import { PageTypes, Theme, ThemeContext } from "assets";
import { graphql } from "gatsby";
import { convertBreadcrumbNavigation, convertHero } from "molecules";
import {
  convertComponentList,
  PageLayout,
  ResourceTopicContent,
} from "organisms";
import PropTypes from "prop-types";
import React from "react";

const ResourcesPageTemplate = ({ data }) => {
  const resourcesData = data?.craftAPI?.entry;
  const exhibitTagList = data?.craftAPI?.entries;

  const componentList = [];

  // Adds exhibit data to exhibit section component data
  resourcesData?.componentList.map((component) => {
    if (
      component.__typename ===
      ("CraftAPI_componentList_exhibitSection_BlockType" ||
        "CraftAPI_componentList_cards_BlockType")
    ) {
      component.bannerLabel = resourcesData.title;
      component.bannerIcon = resourcesData.heroIcon;
      component.bannerColor = resourcesData.buttonColor;
    }
    componentList.push(component);
  });

  const content = convertComponentList(componentList);

  const children = convertHero(resourcesData);
  children.unshift(
    convertBreadcrumbNavigation({
      pageTitle: resourcesData.title,
      parents: resourcesData.parent,
    })
  );

  return (
    <ThemeContext.Provider
      value={{
        theme: resourcesData.theme || Theme.Black,
        fontType: "",
      }}
    >
      <PageLayout
        coverImage={resourcesData.parent?.coverImage}
        pageType={PageTypes.RESOURCES}
        shortDescription={resourcesData.parent?.shortDescription}
        theme={resourcesData.theme || Theme.Black}
        title={resourcesData.title}
      >
        {children}
        <ResourceTopicContent content={content} tagData={exhibitTagList} />
      </PageLayout>
    </ThemeContext.Provider>
  );
};

export const resourcesQuery = graphql`
  query ($uri: [String]) {
    craftAPI {
      entries(type: "exhibit", orderBy: "title") {
        id
        title
      }
      entry(uri: $uri) {
        id
        ...BreadcrumbNavigationFragment
        ... on CraftAPI_resources_resourcesTopic_Entry {
          buttonColor
          coverImage {
            ...ImageMetadataFragment
          }
          coverImageCropStyle
          coverImagePositionOverride
          fontType
          heroIcon
          heroType
          id
          longDescription
          shortDescription
          theme
          title
          uri
          componentList {
            ...ComponentListFragment
          }
        }
      }
    }
  }
`;

ResourcesPageTemplate.propTypes = {
  data: PropTypes.shape({
    craftAPI: PropTypes.shape({
      entries: PropTypes.array,
      entry: PropTypes.shape({}),
    }),
  }),
};

export default ResourcesPageTemplate;
